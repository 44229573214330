import React from "react";
import { Link } from "react-router-dom";
import { useIntl } from "react-intl";

function ServiceItem(props) {
  const intl = useIntl();
  const serviceURL = `/service/${props.title
    .split(" ")
    .join("-")
    .toLowerCase()}?id=${props.id}`;
  return (
    <div className="col-sm-6 col-lg-4">
      <div className="service-item">
        <figure className="service-thumb">
          <Link to={`#`}>
            <img
              src={require("../../assets/img/" + props.thumb)}
              alt={props.title}
            />
          </Link>

          <figcaption className="service-txt">
            <h5>
              {intl.formatMessage({
                id: props.title,
              })}
            </h5>
          </figcaption>
        </figure>
        <div className="service-content">
          <div className="service-content-inner">
            <h5>
              <Link
                to={`#`}
                className="stretched-link"
              >
                {intl.formatMessage({
                  id: props.title,
                })}
              </Link>
            </h5>
            <p>
              {intl.formatMessage({
                id: props.text,
              })}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ServiceItem;
