import React from "react";
import parse from "html-react-parser";

import { Link } from "react-router-dom";
import { useIntl } from "react-intl";

const About = ({ img, content, btn = false, width="col-lg-6" }) => {
  const intl = useIntl();
  return (
    <div
      className="home-two-about-area"
      style={{ backgroundImage: `url(${img})` }}
    >
      <div className="container">
        <div className="row align-items-center">
          <div className="col-12 d-lg-none">
            <figure className="about-thumb">
              <img src={"/#"} alt="Сhancelley-About" />
            </figure>
          </div>

          <div className={width}>
            <div className="about-content about-content--2">
              <h6>
                {intl.formatMessage({
                  id: "main.about.title",
                })}
              </h6>
              <h2>
                {intl.formatMessage({
                  id: "main.about.heading",
                })}
              </h2>
              <span className="about-since">
                {/* {intl.formatMessage({
                  id: "main.about.since",
                })} */}
              </span>

              <p
                dangerouslySetInnerHTML={{
                  __html: intl.formatMessage({
                    id: content,
                  }),
                }}
              />

              {btn ? (
                <Link to="/#" className="btn-about">
                  {intl.formatMessage({
                    id: "main.about.btnText",
                  })}{" "}
                  <i className="fa fa-angle-double-right" />
                </Link>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
