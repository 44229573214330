import React from "react";

function HeaderConfig(props) {
  const LoginRegHandler = () => {
    const offCanvasConfig = document.querySelector(".off-canvas-cog");
    offCanvasConfig.classList.add("active");
    document.querySelector("body").classList.add("fix");
  };

  const MobileMenuHandler = () => {
    const offCanvasMenu = document.querySelector(".off-canvas-menu");
    offCanvasMenu.classList.add("active");
  };

  return (
    <div className='d-flex' style={{ alignItems: "center" }}>
      {/* <div className='dropdown' 
      // style={{ height: "25px" }}
      >
        <button
          className='btn btn-secondary dropdown-toggle'
          type='button'
          id='dropdownMenuButton'
          data-toggle='dropdown'
          aria-haspopup='true'
          aria-expanded='false'
        >
          RU
        </button>
        <div className='dropdown-menu' aria-labelledby='dropdownMenuButton'>
          <a className='dropdown-item' href='#'>
            RU
          </a>
          <a className='dropdown-item' href='#'>
            EN
          </a>
        </div>
      </div> */}
      <div className='header-action mt-lg-3 text-right'>
        <a href='tel:00199823568658' className='tel-no'>
          +7 812 710 1417
        </a>
        <a href='skype:andrey.melnikov_1?call' className='mr-2'>
          <span className='btn-icon'>
            <img
              src='https://chancelley.com/static/media/skype.087596ec.svg'
              className=''
            />
          </span>
        </a>
        <a href='https://t.me/itlawyers' target='_blank'>
          <span className='mr-2 btn-icon'>
            <img
              src='https://chancelley.com/static/media/telegram.acd17e9b.svg'
              className=''
            />
          </span>
        </a>

        {/* <button onClick={LoginRegHandler} className="btn-cog"><i className="fa fa-cog"/></button> */}
        <button onClick={MobileMenuHandler} className='btn-menu d-lg-none'>
          <i className='fa fa-bars' />
        </button>
      </div>
    </div>
  );
}

export default HeaderConfig;
