import React from "react";

const FullScreenLayout = ({ children, color }) => {
  return (
    <>
      <div style={{ minHeight: "100vh" }}> {children} </div>
    </>
  );
};

export default FullScreenLayout;
