import React from "react";
import Routes from "./routes/Routes";

function App() {
    return (
        <React.Fragment>
            <Routes/>
        </React.Fragment>
    );
}

export default App;
