import NotFound from "../pages/ErrorPage/NotFound";
import FullScreenLayout from "../pages/Layouts/FullScreenLayout";
import LandingLayout from "../pages/Layouts/LandingLayout";
import TemplatePage from "../pages/TemplatPage/TemplatePage";
import MainPage from "../pages/HomeTwo/HomeTwo";
import AboutPage from "../pages/About/About";
import CareersPage from "../pages/Careers/Careers";
import ContactsPage from "../pages/Contacts/Contact";

const landingRoutes = {
  children: [
    {
      routeType: "public",
      path: "/",
      name: "Main",
      component: MainPage,
      layout: FullScreenLayout,
    },
    {
      routeType: "public",
      path: "/about",
      name: "About",
      component: AboutPage,
      layout: FullScreenLayout,
    },
    {
      routeType: "public",
      path: "/careers",
      name: "Careers",
      component: CareersPage,
      layout: FullScreenLayout,
    },
    {
      routeType: "public",
      path: "/contact",
      name: "Contacts",
      component: ContactsPage,
      layout: FullScreenLayout,
    },
    {
      routeType: "public",
      path: "/resources",
      name: "Resources",
      component: ContactsPage,
      layout: FullScreenLayout,
    },
    {
      routeType: "public",
      path: "/404",
      name: "Not Found",
      component: NotFound,
      layout: FullScreenLayout,
    },
  ],
};

export const routes = [landingRoutes];
