import {browserHistory} from "helper/browserHistory";
import ScrollTop from "helper/ScrollTop";
import React, {Suspense, useEffect} from "react";
import {BrowserRouter as Router, Redirect, Route, Switch,} from "react-router-dom";

import NotFound from "../pages/ErrorPage/NotFound";
import {routes} from "./index";

const childRoutes = (routes) =>
    routes.map(
        (
            {
                children,
                path,
                name,
                component: Component,
                layout: Layout,
                navBarColor,
            },
            index
        ) =>
            children ? (
                // Route item with children
                children.map(
                    (
                        {
                            routeType,
                            path,
                            name,
                            component: Component,
                            layout: Layout,
                            navBarColor,
                        },
                        index
                    ) => {
                        if (routeType === "public") {
                            return (
                                <Route
                                    key={index}
                                    path={path}
                                    exact
                                    render={(props) => {
                                        return (
                                            <Layout name={name} color={navBarColor}>
                                                <Component {...props} />
                                            </Layout>
                                        );
                                    }}
                                />
                            );
                        }

                        if (routeType === "private") {
                            const userContext = JSON.parse(localStorage.getItem("cv_auth"));
                            return (
                                <Route
                                    key={index}
                                    path={path}
                                    exact
                                    render={(props) => {
                                        if (
                                            userContext !== null &&
                                            userContext.accessToken !== null
                                        ) {
                                            return (
                                                <Layout name={name} color={navBarColor}>
                                                    <Component {...props} />
                                                </Layout>
                                            );
                                        }
                                        {
                                            return <Redirect to="/signin"/>;
                                        }
                                    }}
                                />
                            );
                        }
                    }
                )
            ) : (
                // Route item without children
                <Route
                    key={index}
                    path={path}
                    exact
                    render={(props) => (
                        <Layout name={name} color={navBarColor}>
                            <Component {...props} />
                        </Layout>
                    )}
                />
            )
    );

const Routes = () => {

    return <Suspense fallback={<div>Загрузка...</div>}>
        <Router browserHistory={browserHistory}>
            <ScrollTop>
                <Switch>
                    {childRoutes(routes)}
                    <Route path="*">
                        <NotFound/>
                    </Route>
                </Switch>
            </ScrollTop>
        </Router>
    </Suspense>

}
export default Routes;
