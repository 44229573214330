import React from "react";
import FormInput from "../../components/UI/Input";

const From = () => {
  return (
    <div className='contact-form-wrap'>
      <form id='contact-form'>
        <div className='row'>
          <div className='col-md-6'>
            <FormInput
              tag={"input"}
              type={"text"}
              name={"first_name"}
              placeholder={"Name *"}
            />
          </div>

          <div className='col-md-6'>
            <FormInput
              tag={"input"}
              type={"text"}
              name={"title"}
              placeholder={"Title *"}
            />
          </div>

          {/* <div className='col-md-6'>
            <FormInput
              tag={"input"}
              type={"text"}
              name={"last_name"}
              placeholder={"Last Name *"}
            />
          </div> */}

          <div className='col-md-6'>
            <FormInput
              tag={"input"}
              type={"email"}
              name={"email_address"}
              placeholder={"Email address *"}
            />
          </div>

          <div className='col-md-6'>
            <FormInput
              tag={"input"}
              type={"text"}
              name={"Теlephone"}
              placeholder={"Теlephone"}
            />
          </div>

          {/* 

<div className='col-md-6'>
  <FormInput
    tag={"input"}
    type={"text"}
    name={"tеlefax"}
    placeholder={"Теlefax"}
  />
</div>


          <div className="col-md-6">
            <FormInput
              tag={"input"}
              type={"text"}
              name={"address"}
              placeholder={"Address"}
            />
          </div>

          <div className="col-md-6">
            <FormInput
              tag={"input"}
              type={"text"}
              name={"country"}
              placeholder={"Country"}
            />
          </div>

          <div className="col-md-6">
            <FormInput
              tag={"input"}
              type={"text"}
              name={"lawyer"}
              placeholder={"Lawyer"}
            />
          </div>

                    <div className='col-md-6'>
            <FormInput
              tag={"input"}
              type={"text"}
              name={"company"}
              placeholder={"Company"}
            />
          </div>


          <div className="col-md-6">
            <div className="single-input-item">
              <select>
                <option>All practices</option>
                <option>Corporate</option>
                <option>M/A</option>
                <option>IT/IP</option>
                <option>Tax</option>
                <option>Banking/Finance</option>
                <option>Energy</option>
                <option>Real Estate</option>
                <option>International Trade</option>
                <option>Litigation</option>
                <option>Employment</option>
                <option>Project Finance</option>
                <option>Private Equity</option>
              </select>
            </div>
          </div>

          <div className="col-md-6">
            <div className="single-input-item">
              <select>
                <option>All offices</option>
                <option>London</option>
                <option>Paris</option>
                <option>Brusseles</option>
                <option>Saint-Petersburg</option>

              </select>
            </div>
          </div> 

        */}
          <div className='col-12'>
            <FormInput
              tag={"textarea"}
              name={"con_message"}
              placeholder={"Write Your Message *"}
            />

            <FormInput tag={"button"} classes={"btn-outline"} />

            <div className='form-message' />
          </div>
        </div>
      </form>
    </div>
  );
};

export default From;
