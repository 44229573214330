import ru from './ru.json';
import en from './en.json';

const languages = {
  ru,
  en,
};

export const local = navigator.language;

if (!localStorage.getItem('language')) {
  localStorage.setItem('language', local.split(/[-_]/)[0]);
}

export const messages = languages[localStorage.getItem('language')];
