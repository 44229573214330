import React, { Fragment } from "react";
import Header from "../../components/Header";
import ContactPage from "../../templates/Contact";
import CallToAction from "../../components/CallToAction";
import Footer from "../../components/Footer";
import LoginRegister from "../../components/LoginRegister";
import MobileMenu from "../../components/MobileMenu";
import About from "../../components/About/page";
import { useIntl } from "react-intl";
import ServiceThumb from "../../assets/img/lighthouse.jpg";

const PageContact = () => {
    const intl = useIntl();
  return (
    <Fragment>
      <Header />
      <About
        // title={"Career"}
        heading="Contact Us"
        thumb={ServiceThumb}
        content={intl.formatMessage({
          id: "contacts.description",
        })}
      />
      <ContactPage />
      <CallToAction />
      <Footer />
      <LoginRegister />
      <MobileMenu />
    </Fragment>
  );
};

export default PageContact;
