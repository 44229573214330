import React, { Fragment } from "react";
import Header from "../../components/Header";
import PageHeader from "../../components/PageHeader";
import About from "../../components/About/page";
import TeamMember from "../../templates/Team";
import BrandLogo from "../../components/BrandLogo";
import Funfact from "../../components/Funfact";
import CallToAction from "../../components/CallToAction";
import Footer from "../../components/Footer";
import LoginRegister from "../../components/LoginRegister";
import MobileMenu from "../../components/MobileMenu";
import { useIntl } from "react-intl";
import ServiceThumb from "../../assets/img/about.jpg";

const Careers = () => {
  const intl = useIntl();
  return (
    <Fragment>
      <Header />
      <PageHeader
        bgImg={require("../../assets/img/page-header.jpg")}
        title="Career"
      />
      <About
        title={"Career"}
        heading="Why join us?"
        thumb={ServiceThumb}
        content={intl.formatMessage({
          id: "careers.description-one",
        })}
      />
      {/*<TeamMember*/}
      {/*  content={intl.formatMessage({*/}
      {/*    id: "careers.description-two",*/}
      {/*  })}*/}
      {/*/>*/}
      <Funfact classes="sp-top" />
      <CallToAction />
      <Footer />
      <LoginRegister />
      <MobileMenu />
    </Fragment>
  );
};

export default Careers;
