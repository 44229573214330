import React from "react";
import Text from "../UI/Text";
import Widget from "../UI/Widget";
import List from "../UI/List";
import LI from "../UI/List/Item";
import { Link } from "react-router-dom";
import LogoDark from "../../assets/img/LOGO_DARK.svg";
import { useIntl } from "react-intl";

function Footer() {
  const intl = useIntl();

  return (
    <footer className='footer-area sp-bottom'>
      <div className='container'>
        <div className='row mtn-40'>
          <div className='col-lg-4 order-4 order-lg-0'>
            <div className='widget-item'>
              <div className='about-widget'>
                <Link to={`${process.env.PUBLIC_URL + "/"}`}>
                  <img src={LogoDark} alt='Logo Dark' />
                </Link>

                <Text>
                  {intl.formatMessage({
                    id: "main.adding-two",
                  })}
                </Text>

                <Text classes='copyright-txt'>
                  © 2014 Chancelley Law Offices International LLP. All rights
                  reserved.
                </Text>
              </div>
            </div>
          </div>

          <div className='col-md-4 col-lg-2 ml-auto'>
            <Widget title='Information'>
              <List classes='widget-list'>
                <LI>
                  <Link to={`${process.env.PUBLIC_URL + "/about"}`}>
                    Our company
                  </Link>
                </LI>
                {/* <LI>
                  <Link to={`${process.env.PUBLIC_URL + "/contact"}`}>
                    Contact us
                  </Link>
                </LI> */}
                {/* <LI>
                  <Link to={`${process.env.PUBLIC_URL + "/services"}`}>
                    Our services
                  </Link>
                </LI> */}
                <LI>
                  <Link to={`${process.env.PUBLIC_URL + "/careers"}`}>Careers</Link>
                </LI>
              </List>
            </Widget>
          </div>

          <div className='col-md-4 col-lg-2 ml-auto'>
            <Widget title='Social Links'>
              <List classes='widget-list'>
                <LI>
                  <a href='skype:andrey.melnikov_1?call'>SKYPE</a>
                </LI>

                <LI>
                  <a href='https://t.me/itlawyers' target={"_blank"}>
                    TELEGRAM
                  </a>
                </LI>
              </List>
            </Widget>
          </div>

          {/* <div className='col-md-4 col-lg-3'>
            <Widget title='Contact Us'>
              <address>
                <p
                  dangerouslySetInnerHTML={{
                    __html: intl.formatMessage({
                      id: "main.contact-us",
                    }),
                  }}
                />
              </address>
            </Widget>
          </div> */}
        </div>
      </div>
    </footer>
  );
}

export default Footer;
