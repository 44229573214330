import React from "react";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";

const NotFound = () => {
  const intl = useIntl();
  const history = useHistory();
  React.useEffect(() => {
      console.log("HISTORY", history)
  } ,[])

  return (
    <div className='inner inner-pages'>
      <div className='main'>
        <section
          id='home'
          className='section welcome-area inner-area bg-overlay h-100vh overflow-hidden'
        >
          <div className='container h-100'>
            <div
              className='row align-items-center justify-content-center h-100'
              style={{ marginTop: "28%" }}
            >
              <div className='col-12 col-md-7 my-auto'>
                <div className='welcome-intro error-area text-center'>
                  <h1 className='text-black'>404</h1>
                  <p className='text-black my-4'>
                    {intl.formatMessage({
                      id: "404.removed",
                    })}
                  </p>
                  <button
                    onClick={() => {
                      history.goBack();
                    }}
                    className='btn sApp-btn text-uppercase'
                  >
                    {intl.formatMessage({
                      id: "404.to-homepage",
                    })}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default NotFound;
